import AppProvider from 'store/app'
import PropTypes from 'prop-types'
import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'

import 'focus-visible'
import 'typeface-inter'

smoothscroll.polyfill()

export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>
}

wrapRootElement.propTypes = {
  element: PropTypes.node,
}
