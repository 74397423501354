import colors from './colors'

export { default as colors } from './colors'

export const breakpoints = ['32rem', '54rem', '64rem']

export const fonts = {
  body:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  monospace:
    '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
}

fonts.heading = fonts.body

export const fontSizes = [
  '3.75rem',
  '3.25rem',
  '2.5rem',
  '1.75rem',
  '1.25rem',
  '1rem',
  '0.875rem',
  '0.75rem',
  '0.625rem',
]

fontSizes.min = '15px'
fontSizes.base = '16px'

export const fontWeights = {
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  black: 800,
}

export const letterSpacings = [
  '-0.0625rem',
  '-0.05rem',
  '-0.025rem',
  '-0.0125rem',
  '-0.006125rem',
  '0',
  '0.125rem',
]

letterSpacings.normal = letterSpacings[5]
letterSpacings.spaced = letterSpacings[6]
letterSpacings.tight = letterSpacings[1]

export const lineHeights = [1, 1.25, 1.5, 2]

export const borders = ['none', 'solid 1px', 'solid 2px', 'solid 5px']

export const radii = [0, 4, 8, 16, '25%', '50%', 9999]

export const shadows = [
  'none',
  `0 1px 3px ${colors['shadow-100']}`,
  `0 3px 9px ${colors['shadow-200']}`,
  `0 10px 30px ${colors['shadow-300']}`,
  `0 30px 90px ${colors['shadow-400']}`,
].map((shadow, i, source) =>
  source
    .slice(1, i)
    .concat([shadow])
    .join(', ')
)

export const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

export const sizes = ['initial', '64rem', '72rem', '80rem']

sizes.emoji = '4rem'
sizes.tight = '9em'
sizes.narrow = '18em'
sizes.normal = '24em'
sizes.wide = '32em'

export const transitions = [
  '0ms',
  '125ms',
  '250ms',
  '375ms',
  '500ms',
  '625ms',
  '750ms',
  '1000ms',
  '1250ms',
]

transitions.none = transitions[0]
transitions.quick = transitions[1]
transitions.default = transitions[2]
transitions.slow = transitions[4]

export default {
  borders,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  sizes,
  space,
  transitions,
}
