import { rgba } from 'polished'

const colors = {
  white: '#FFFFFF',

  'neutral-025': '#FCFDFF',
  'neutral-050': '#F5F7FA',
  'neutral-100': '#E8EAED',
  'neutral-200': '#CBD2D9',
  'neutral-300': '#9AA5B1',
  'neutral-400': '#7B8794',
  'neutral-500': '#616E7C',
  'neutral-600': '#4D5966',
  'neutral-700': '#3E4C59',
  'neutral-800': '#323F4B',
  'neutral-900': '#1F2933',
  'neutral-1000': '#181F26',
  'neutral-1100': '#151B21',

  'primary-025': '#E6EDF2',
  'primary-050': '#E6EDF2',
  'primary-100': '#CEE2F2',
  'primary-200': '#ABC4D9',
  'primary-300': '#3C566E',
  'primary-400': '#2D4459',
  'primary-500': '#23374A',

  'purple-025': '#F0F4FD',
  'purple-050': '#E0E8F9',
  'purple-100': '#BED0F7',
  'purple-200': '#98AEEB',
  'purple-300': '#7B93DB',
  'purple-400': '#647ACB',
  'purple-500': '#4C63B6',
  'purple-600': '#4055A8',
  'purple-700': '#35469C',
  'purple-800': '#2D3A8C',
  'purple-900': '#19216C',

  'blue-025': '#F1FBFE',
  'blue-050': '#E3F8FF',
  'blue-100': '#BFF0FF',
  'blue-200': '#81DEFD',
  'blue-300': '#5ED0FA',
  'blue-400': '#40C3F7',
  'blue-500': '#2BB0ED',
  'blue-600': '#1992D4',
  'blue-700': '#127FBF',
  'blue-800': '#0B69A3',
  'blue-900': '#035388',

  'red-025': '#FFF5F5',
  'red-050': '#FFE3E3',
  'red-100': '#FFBDBD',
  'red-200': '#FF9B9B',
  'red-300': '#F86A6A',
  'red-400': '#EF4E4E',
  'red-500': '#E12D39',
  'red-600': '#CF1124',
  'red-700': '#AB091E',
  'red-800': '#8A041A',
  'red-900': '#610316',

  facebook: '#217BEE',
  instagram: '#E4405F',
  twitter: '#1DA1F2',
  youtube: '#AE1F1E',
  reddit: '#FF4500',
  amazon: '#FE9A2F',
}

// Aliases
colors.base = colors['neutral-025']
colors.text = colors['neutral-900']
colors.primary = colors['primary-400']

colors['shadow-100'] = rgba(colors.text, 0.05)
colors['shadow-200'] = rgba(colors.text, 0.075)
colors['shadow-300'] = rgba(colors.text, 0.1)
colors['shadow-400'] = rgba(colors.text, 0.125)

colors.blue = colors['blue-700']
colors.purple = colors['purple-500']
colors.red = colors['red-500']

export default colors
