import React, { createContext, useState } from 'react'
import theme from 'styles/theme'
import { ThemeProvider } from 'styled-components'

export const AppContext = createContext()

const AppProvider = props => {
  const [appState, setAppState] = useState({
    focusSubscriptionForm: () => {
      const form = document.querySelector('#subscription_form')
      form.scrollIntoView({ behavior: 'smooth' })
      let scrollPosition = document.body.scrollHeight

      const focusIfVisible = setInterval(() => {
        const bounds = form.getBoundingClientRect()
        const isVisible = bounds.top + bounds.height < window.innerHeight

        if (isVisible) {
          document.querySelector('#subscriber_name').focus()
          clearInterval(focusIfVisible)
        }

        if (scrollPosition > bounds.top) {
          scrollPosition = bounds.top
        } else {
          clearInterval(focusIfVisible)
        }
      }, 400)
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={{ appState, setAppState }} {...props} />
    </ThemeProvider>
  )
}

export default AppProvider
